import { css } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';

import { constants } from '../../common/GenreHomeBook';

export const hookSentenceStyle = css`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.01em;
  ${lineClamp(2, '20px')};
  white-space: pre-line;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 15px;
      ${lineClamp(2, '18px')}
      white-space: pre-line;
    `,
  )};
`;

export const bookFastRendererStyle = css`
  width: ${constants.DESKTOP_BOOK_DEFAULT_COVER_WIDTH}px;
  height: 309px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${constants.MOBILE_BOOK_DEFAULT_COVER_WIDTH}px;
      height: 214px;
    `,
  )};
`;
