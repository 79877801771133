import { useCallback, useContext, useMemo } from 'react';

import {
  BookDataContext,
  BookDefinition,
  BookDefinitionContext,
  BookNothing,
  BookPreset,
} from '@/components/common/Book';
import { GenreHomeBasePreset, GenreHomeBook } from '@/components/genreHome/common/GenreHomeBook';
import { ListLayoutSection } from '@/components/genreHome/common/ListLayoutSection';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import {
  SectionLayoutType,
  SelectionHookingSentenceItem as SelectionHookingSentenceItemType,
} from '@/models/backendsApi/v2/Views/ViewsType';
import { modularComponent } from '@/utils/modularComponent';

import * as styles from './SelectionHookingSentence.styles';

const SelectionHookingSentenceBookTitle = modularComponent(() => () => {
  const { BookTitleBadgeRenderer } = useContext(BookDefinitionContext).components;
  const description = useContext(BookDataContext).metadata?.extra?.description;

  if (!description) {
    return <></>;
  }

  return (
    <div css={styles.hookSentenceStyle}>
      <BookTitleBadgeRenderer />
      {description}
    </div>
  );
});

const SelectionHookingSentenceBasePresets: BookPreset = baseComponents => ({
  ...baseComponents,
  BookFastRenderer: baseComponents.BookFastRenderer.addStyle(styles.bookFastRendererStyle),
  BookTitle: SelectionHookingSentenceBookTitle,
  BookMetadataItems: BookNothing,
});

const SelectionHookingSentencePresets = [...GenreHomeBasePreset, SelectionHookingSentenceBasePresets];

interface SelectionHookingSentenceBookItemProps {
  item: BookViewItem<SelectionHookingSentenceItemType>;
  index: number;
}

const SelectionHookingSentenceBookItemRenderer = ({
  item,
  index,
}: SelectionHookingSentenceBookItemProps): ReactJSX.Element => {
  const { book, hooking_sentence: hookingSentence } = item.contents;
  const data = useMemo(
    () => ({
      ...book,
      metadata: {
        ...book.metadata,
        extra: { ...book.metadata?.extra, description: hookingSentence },
      },
    }),
    [book, hookingSentence],
  );

  return <GenreHomeBook book={data} index={index} />;
};

export interface SelectionHookingSentenceProps {
  section: SectionByLayout<SectionLayoutType.SelectionHookingSentence>;
}

export const SelectionHookingSentence = ({ section }: SelectionHookingSentenceProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const render = useCallback(
    ({ item, index }: { item: BookViewItem<SelectionHookingSentenceItemType>; index: number }) => (
      <SelectionHookingSentenceBookItemRenderer item={item} index={index} />
    ),
    [],
  );

  return (
    <BookDefinition presets={SelectionHookingSentencePresets} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout title={section.title} paddingBottom={false}>
        <ListLayoutSection items={section.items} renderItem={render} />
      </SectionLayout>
    </BookDefinition>
  );
};
